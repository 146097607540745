import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { CustomButton, ButtonText } from "./styled";
import { LoadingOutlined } from "@ant-design/icons";

const PButton = forwardRef(({
  ptype,
  pname,
  pspin,
  ploading,
  width,
  height,
  pleft,
  pright,
  pbottom,
  ptop,
  mleft,
  mright,
  mbottom,
  mtop,
  bRadius,
  bgColor,
  hoverBgColor,
  disabledBgColor,
  color,
  shadow,
  children,
  ...rest
}, ref) => {
  return (
    <CustomButton
      ref={ref}
      ptype={ptype}
      height={height}
      width={width}
      pleft={pleft}
      pright={pright}
      ptop={ptop}
      pbottom={pbottom}
      mleft={mleft}
      mright={mright}
      mtop={mtop}
      mbottom={mbottom}
      bradius={bRadius}
      bgcolor={bgColor}
      hoverbgcolor={hoverBgColor}
      disabledbgcolor={disabledBgColor}
      color={color}
      shadow={shadow}
      {...rest}
    >
      {children || (
        <React.Fragment>
          <ButtonText ptype={ptype} color={color}>
            {pname}
          </ButtonText>
          {pspin && ploading && <LoadingOutlined />}
        </React.Fragment>
      )}
    </CustomButton>
  );
});

PButton.displayName = 'PButton';

PButton.propTypes = {
  ptype: PropTypes.string,
  pname: PropTypes.string,
  pspin: PropTypes.bool,
  ploading: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  pleft: PropTypes.string,
  pright: PropTypes.string,
  ptop: PropTypes.string,
  pbottom: PropTypes.string,
  mleft: PropTypes.string,
  mright: PropTypes.string,
  mtop: PropTypes.string,
  mbottom: PropTypes.string,
  bRadius: PropTypes.string,
  bgColor: PropTypes.string,
  hoverBgColor: PropTypes.string,
  disabledBgColor: PropTypes.string,
  color: PropTypes.string,
  shadow: PropTypes.string,
  children: PropTypes.node,
};

PButton.defaultProps = {
  ptype: "primary",
  pspin: false,
  ploading: false,
  pname: "BUTTON",
  height: "38px",
};

export default PButton;
